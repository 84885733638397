.cookieAcceptBar {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	background-color: #fbf4ec;
	color: black;
	padding: 20px 0;
	z-index: 99999;
	border-color: black;
	border-top: 1px;
	overflow-x: hidden;
	font-family: Yanone Kaffeesatz, sans-serif;
	font-weight: 600;
	font-size: 22px;

	a {
		color: #00afa9;
		text-decoration: none;
		font-weight: bold;
		text-decoration: underline;
	}

	button {
		cursor: pointer;
		border: none;
		background-color: #081519;
		color: black;
		text-transform: uppercase;
		margin-top: 10px;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
	}

	.btn-success {
		color: white;
		background-color: #00afa9;
		border-color: #e2dad1;

		&.focus,
		:focus {
			box-shadow: 0 0 0 0.2rem rgba(8, 21, 25, 0.5);
		}
		&:hover {
			background-color: #4ec2bd;
		}
	}
}
